@media (max-width: $screen-lg) {
    // lg-md view
    .back-to-top {
        right: 10px;
        bottom: 10%;
    }
}

@media (max-width: $screen-md) {
    // md-view
    
}

@media (max-width: $screen-sm) {
    // sm-view
    html {
        font-size: $font-size-base-xs;
        line-height: $line-height-computed-xs;
    }

    .wrapper {
    	min-width: 290px;
    	max-width: 100%;
    }

    .wrapper-inside {
        width: 90.625%;
    }

    .pd-bottom {
        padding-bottom: 30px;
    }
    
    .pd-top {
        padding-top: 30px;
    }

    .mg-bottom {
        margin-bottom: 30px;
        &.mg-bottom-xs-10 {
            margin-bottom: 10px;
        }
        &.mg-bottom-xs-20 {
            margin-bottom: 20px;
        }
        &.mg-bottom-xs-40 {
            margin-bottom: 40px;
        }
    }

    .xs-no-pd-bottom {
        padding-bottom: 0;
    }

    .xs-no-pd-top {
        padding-top: 0;
    }

    .xs-no-mg-bottom {
        margin-bottom: -3px;
    }

    .xs-common-border-no-radius {
        border-radius: 0px !important;
    }


    /* Header
    -----------------------------------------------------------------------------*/
    .header {

    }

    /* Middle
    -----------------------------------------------------------------------------*/
    .content {
        padding: 0 0 $footer-size-xs;
    }

    /* Footer
    -----------------------------------------------------------------------------*/
    .footer {
        margin: -$footer-size-xs auto 0;
        height: $footer-size-xs;
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}

@media (max-width: $screen-sm) {
    // sm-view
    form {
        .action {
            &.full {
                .agree-col {
                    margin: 10px 0 0;
                }
            }
            .agree-col {
                font-size: 8px;
                line-height: 11px;
            }
        }

        .form-group {
            width: 100%;
            margin: 0;
            .form-col {
                padding: 0;
                &.col-50 {
                    width: 100%;
                }
                &.col-33 {
                    width: 100%;
                }
                &.col-66 {
                    width: 100%;
                }
            }
        }

        .form-field {
            label.input {
                &.captcha {
                    input {
                        width: 51.5%;
                    }
                }

                input, textarea {
                    font-size: 16px;
                    height: 40px;
                    @include placeholder {
                        font-size: 16px;
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}

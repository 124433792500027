.cols {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 30px);
    margin-left: -15px;
    margin-bottom: -30px;
    .col {
        width: 100%;
        height: auto;
        box-sizing: border-box;
        padding: 0 15px;
        margin: 0 0 30px;
        &.col-20 {
            width: 20%;
        }
        &.col-25 {
            width: 25%;
        }
        &.col-33 {
            width: 33.333%;
        }
        &.col-50 {
            width: 50%;
        }
        &.col-66 {
            width: 66.666%;
        }
        &.col-75 {
            width: 75%;
        }
        &.col-80 {
            width: 80%;
        }
    }
}

@import "../../media/tablet/includes/common/cols";
@import "../../media/mobile/includes/common/cols";

.phones-block { 
    margin-right: 15px;
    position: relative;
    &:hover {
        .phones-dropdown { 
            transform: translateX(-50%) translateY(0px);
            opacity: 1;
            touch-action: auto;
            pointer-events: all;
            transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
        }
        >.btn { 
            background-color: $brand-primary-accent;
            border-color: $brand-primary-accent;
        }
    }
    >.btn { 
        border-radius: 50%;
        padding: 0;
        width: 45px;
        height: 45px;
        line-height: 41px;
        text-align: center;
        svg { 
            vertical-align: middle;
        }
    }

    .phones-dropdown { 
        position: absolute;
        left: 50%;
        top: 100%;
        padding: 30px 0 0;
        transform: translateX(-50%) translateY(-10px);
        width: 274px;
        opacity: 0;
        touch-action: none;
        pointer-events: none;
        transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
        .phones-dropdown-holder { 
            display: inline-block;
            width: 100%;
            background-color: white;
            box-sizing: border-box;
            padding: 15px 16px;
            overflow: hidden;
            .phones { 
                display: inline-block;
                width: 100%;
                .phone { 
                    display: inline-block;
                    width: 100%;
                    &:not(:last-of-type) {
                        margin: 0 0 10px;
                    }
                    .btn { 
                        display: inline-block;
                        width: 100%;
                        display: inline-block;
                        background-color: white;
                        border-color: white;
                        border-radius: 20px;
                        padding: 13px 14px;
                        height: auto;
                        &:hover {
                            background-color: $bg-secondary;
                            border-color: $bg-secondary;
                        }
                        .number { 
                            display: inline-block;
                            width: 100%;
                            color: $gray-base;
                            white-space: nowrap;
                        }
    
                        .note { 
                            display: inline-block;
                            width: 100%;
                            color: $gray;
                            font-size: 14px;
                            line-height: 14px;
                            font-weight: 400;
                            white-space: nowrap;
                            text-align: right;
                        }
                    }
                }
            }

            .action {
                width: 100%;
                .btn {
                    width: 100%;
                    text-align: center;
                    padding: 12px 10px;
                }
            }
        }
    }
}

@import "../../media/tablet/includes/base/phones_block";
@import "../../media/mobile/includes/base/phones_block";

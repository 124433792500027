@font-face {
    font-family: 'AustinCyr';
	src: url('./AustinCyr-Roman.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
    font-family: 'AustinCyr';
	src: url('./AustinCyr-Bold.otf') format('opentype');
	font-weight: bold;
	font-style: normal;
}

@media (max-width: $screen-lg) {
    // lg-md view
    .cols {
        .col {
            &.col-20-lg {
                width: 20%;
            }
            &.col-25-lg {
                width: 25%;
            }
            &.col-33-lg {
                width: 33.333%;
            }
            &.col-50-lg {
                width: 50%;
            }
            &.col-66-lg {
                width: 66.666%;
            }
            &.col-75-lg {
                width: 75%;
            }
            &.col-80-lg {
                width: 80%;
            }
            &.col-100-lg {
                width: 100%;
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
    .cols {
        width: calc(100% + 20px);
        margin-left: -10px;
        margin-bottom: -20px;
        .col {
            padding: 0 10px;
            margin: 0 0 20px;
            &.col-20-md {
                width: 20%;
            }
            &.col-25-md {
                width: 25%;
            }
            &.col-33-md {
                width: 33.333%;
            }
            &.col-50-md {
                width: 50%;
            }
            &.col-66-md {
                width: 66.666%;
            }
            &.col-75-md {
                width: 75%;
            }
            &.col-80-md {
                width: 80%;
            }
            &.col-100-md {
                width: 100%;
            }
        }
    }
}

.ps.custom-scroll {
    position: relative;
    .ps__rail-y {
        position: absolute;
        opacity: 1;
        width: 5px;
        background-color: $bg-secondary;
        border-radius: 3px;
        margin-right: 3px;
        .ps__thumb-y {
            border-radius: 3px;
            width: 5px;
            right: 0;
            background: $brand-primary;
            &:hover {
                cursor: pointer;
            }
            &:focus {
                outline: none;
            }
        }
        &.ps--clicking {
            background-color: $brand-primary-accent;
            .ps__thumb-y {
                width: 5px;
                right: 0;
                background: $brand-primary;
                &:hover {
                    cursor: pointer;
                }
            }
        }
        &:hover {
            .ps__thumb-y {
                width: 5px;
                background: $brand-primary;
            }
        }
    }
    &:hover {
        .ps__rail-y {
            opacity: 1;
            width: 5px;
            background-color: $bg-secondary;
            border-radius: 3px;
        }
    }
}

@media (max-width: $screen-lg) {
    // lg-md view
    .wrapper {
        min-width: 1200px;
        max-width: 100%;
    }

    .wrapper-inside {
        width: 1200px;
    }
}

@media (max-width: $screen-md) {
    // md-view

    .common-border-radius {
        border-radius: 15px !important;
    }

    .pd-bottom {
        padding-bottom: 40px;
    }
    
    .pd-top {
        padding-top: 40px;
    }

    .mg-bottom {
        margin-bottom: 20px;
        &.mg-bottom-md-30 {
            margin-bottom: 33px;
        }
    }

    html {
        font-size: $font-size-base-md;
        line-height: $line-height-computed-md;
    }

    .wrapper {
        min-width: 590px;
        max-width: 100%;
    }

    .wrapper-inside {
        width: 92.806%;
    }

    /* Header
    -----------------------------------------------------------------------------*/
    .header {

    }

    /* Middle
    -----------------------------------------------------------------------------*/
    .content {
        padding: 0 0 $footer-size-md;
    }   

    /* Footer
    -----------------------------------------------------------------------------*/
    .footer {
        margin: -$footer-size-md auto 0;
        height: $footer-size-md;
    }
}

@import "../mixins";

$menu-width: 100%;

/*Mobile menu
-----------------------------------------------------------------------------*/
.mobile-menu {
	.mobile-menu-holder {
		.menu-background {
			display: none;
			position: fixed;
			z-index: 1201;
			top: 0;
			left: 0;
			background: $gray-base;
			opacity: 0.64;
			width: 100%;
			height: 100%;
			@include transition-easy-in-out(250ms);
			&.animated {
				opacity: 0;
				@include transition-easy-in-out(250ms);
			}
			&.closed {
				display: none;
			}
		}
		.menu-body {
			position: fixed;
			z-index: 1202;
			top: 0;
			left: 0;
			width: $menu-width;
			height: 100%;
			box-sizing: border-box;
			padding: 0;
			background: transparent;
			opacity: 1;
			transform: translateY(0);
			overflow-y: hidden;
			padding: 80px 0 0;
			box-sizing: border-box;
			background-color: white;
			@include transition-easy-in-out(350ms);
			&.animated {
				transform: translateY(-$menu-width);
				@include transition-easy-in-out(350ms);
			}
			&.closed {
				display: none;
			}
            &.mobile-offset {

            }
			.main-body {
				display: block;
				overflow-y: hidden;
			    overflow-x: hidden;
			    padding-top: 0;
				height: 100%;
			}
            /*naib body contents*/
		}
	}
}

@import "../media/mobile/includes/mobile_menu";
@import "../media/tablet/includes/mobile_menu";

.fancybox__container {
    z-index: 1250 !important;
}
.general-popup.fancybox__content {
    padding: 50px 40px;
    box-sizing: border-box;
    border-radius: 20px;
}

.popup {
    position: relative;
    .custom-close {
        position: absolute;
        right: 15px;
        top: 15px;
        a {
            display: inline-block;
            text-decoration: none;
            width: 45px;
            height: 45px;
            line-height: 45px;
            background-color: $brand-primary;
            border-radius: 50%;
            text-align: center;
            &:hover {
                background-color: $brand-primary-accent;
            }
            svg {
                vertical-align: middle;
                width: 15px;
                height: 15px;
                color: white;
                transition: all 300ms;
            }
        }
    }
}

.general-popup-container {
    max-width: 515px;
    .general-popup-container-holder {
        display: inline-block;
        width: 100%;
        .success {
            display: inline-block;
            width: 100%;
            .success-holder {
                display: inline-block;
                width: 100%;
                .icon {
                    display: inline-block;
                    width: 100%;
                    text-align: center;
                    margin: 0 0 20px;
                    .icon-holder {
                        display: inline-block;
                        background-color: $brand-primary;
                        border-radius: 50%;
                        width: 100px;
                        height: 100px;
                        line-height: 100px;
                        color: white;
                        text-align: center;
                        svg {
                            width: 33px;
                            height: auto;
                            vertical-align: middle;
                        }
                    }
                }
                .text {
                    display: inline-block;
                    width: 100%;
                    .text-holder {
                        display: inline-block;
                        width: 100%;
                        text-align: center;
                    }
                }
            }
        }

        .form-heading {
            display: inline-block;
            width: 100%;
            text-align: center;
            margin: 0 0 40px;
            .text {
                display: inline-block;
                width: 100%;
            }

            .note {
                width: 71%;
                margin: 15px auto 0;
                font-size: 16px;
                line-height: 140%;
                color: $gray;
            }
        }

        .form {
            form {
                .form-group {
                    .form-col {

                    }
                }

                .form-field {
                    label.input {
                        input, textarea {

                        }

                        textarea {

                        }
                    }
                }

                .action {
                    margin: 10px 0 0;
                }
            }
        }
    }
}

@import "../../media/tablet/includes/popups/custom_fancybox";
@import "../../media/mobile/includes/popups/custom_fancybox";

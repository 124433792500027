@media (max-width: $screen-lg) {
    // lg-md view
    .header-holder { 

        .header-top { 
            .header-top-holder { 
                .logo { 

                }

                .main-menu { 
                    margin-left: 15px;
                    nav { 

                        >ul { 
                            margin: 0 -3.5px;
                            >li { 
                                padding: 0 3.5px;
                                >a {
                                    padding: 11.5px 10px;
                                }
                            }
                            >li.has-sub-menu {
                                .sub-menu {
                                    .sub-menu-holder {
                                        >ul {
                                            column-gap: 30px;
                                            >li {

                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .right { 
                    

                    .additionals { 
                        .add { 

                        }
                    }

                    .action { 
                        .btn {
                            width: 161px;
                            padding: 11.5px 10px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
    .header-holder { 
        padding: 20px 0 15px;

        .additionals { 
            margin-right: 20px;
            .add { 
                margin: 0 5px;
                .add-holder.search-js {
                    &:hover {
                        .search-form-dropdown { 
                            transform: translateX(0px) translateY(0px);
                        }
                    }
                    .search-form-dropdown { 
                        padding: 15px 0 0;
                        left: auto;
                        right: 0;
                        transform: translateX(0px) translateY(-10px);
                    }
                }
                .add-holder.language-select {
                    .language-select-block { 
                        padding: 15px 0 0;
                    }
                }
                .add-holder { 
                    >.btn {
                        width: 40px;
                        height: 40px;
                        line-height: 37px;
                        svg {
                            width: 20px;
                            height: 20px;
                        }
                    }
                }
            }
        }

        .header-top { 
            .header-top-holder { 
                padding: 5px 5px 5px;
                .logo { 
                    a {
                        width: 107px;
                    }
                }

                .main-menu { 
                    margin-left: -4px;
                    nav {
                        >ul {
                            margin: 0 -11px;
                            >li { 
                                padding: 0 11px;
                                font-size: 12px;
                                >a {
                                    padding: 0;
                                    background: none;
                                    border: none;
                                }
                            }
                            >li.has-sub-menu {
                                .sub-menu {
                                    padding: 30px 0 0;
                                    .sub-menu-holder {
                                        padding: 22.5px 20px;
                                        >ul {
                                            column-count: 1;
                                            column-gap: 0px;
                                            >li {
                                                &:not(:last-of-type) {
                                                    margin: 0 0 12.5px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .right { 

                    .phones-block { 
                        margin: 0;
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-sm) {
    // sm-view
    .cols {
        width: calc(100% + 10px);
        margin-left: -5px;
        margin-bottom: -10px;
        .col {
            padding: 0 5px;
            margin: 0 0 10px;
            &.col-20-xs {
                width: 20%;
            }
            &.col-25-xs {
                width: 25%;
            }
            &.col-33-xs {
                width: 33.333%;
            }
            &.col-50-xs {
                width: 50%;
            }
            &.col-66-xs {
                width: 66.666%;
            }
            &.col-75-xs {
                width: 75%;
            }
            &.col-80-xs {
                width: 80%;
            }
            &.col-100-xs {
                width: 100%;
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}

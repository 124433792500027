@media (max-width: $screen-lg) {
    // lg-md view
    .phones-block { 
        &:hover {
            .phones-dropdown { 
                transform: translateX(0px) translateY(0px);
            }
        }

        .phones-dropdown { 
            left: auto;
            right: 0;
            transform: translateX(0px) translateY(-10px);
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
    .phones-block { 
        &:hover {
            .phones-dropdown { 
                transform: translateX(0px) translateY(0px);
            }
        }
        >.btn { 
            width: 40px;
            height: 40px;
            line-height: 37px;
        }

        .phones-dropdown { 
            width: 224px;
            left: auto;
            right: 0;
            transform: translateX(0px) translateY(-10px);
            padding: 15px 0 0;
            .phones-dropdown-holder { 
                padding: 5px 5px;
            }
        }
    }
}

@media (max-width: $screen-lg) {
    // lg-md view
}

@media (max-width: $screen-md) {
    // md-view
    ul.slick-dots {
        li {
            button {
                width: 10px;
                height: 10px;
            }
        }
    }

    .slick-slider {
        width: calc(100% + 20px);
        margin-left: -10px;
        .slick-list {
            .slick-track {
                .slick-slide {
                    padding: 0 10px;
                }
            }
        }
    }
}

@media (max-width: $screen-sm) {
    // sm-view
    .btn {
        font-size: $font-size-small-xs;
	    line-height: 17px;
        padding: 10px 24px;
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}

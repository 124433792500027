@media (max-width: $screen-lg) {
    // lg-md view
}

@media (max-width: $screen-md) {
    // md-view
    form {

        .form-group {
            width: calc(100% + 20px);
            margin-left: -10px;
            .form-col {
                padding: 0 10px;
            }
        }

        .form-field {
            label.input {

                &.captcha {
                    input {
    
                    }
    
                    .image {
                        margin-right: 0;
                        img {
                            max-width: 41px;
                        }
                    }
                }

                input, textarea {
                    font-size: 14px;
                    height: 40px;
                    @include placeholder {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-lg) {
    // lg-md view
}

@media (max-width: $screen-md) {
    // md-view
    .btn {
        font-size: $font-size-base-md;
	    line-height: $line-height-computed-md;
        padding: 10px 34px;
    }
}

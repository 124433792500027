.footer-holder { 
    display: inline-block;
    width: 100%;
	.footer-top { 
        display: inline-block;
        width: 100%;
		.footer-top-holder { 
            display: inline-block;
            width: 100%;
            border-bottom: 1px solid $gray-light;
            padding: 37px 0 29px;
            .left { 
                float: left;
                .logo { 
                    float: left;
                    margin-right: 58px;
                    a { 

                        svg {

                        }
                    }
                }

                .outer-links { 
                    float: left;
                    margin: 0 -10px;
                    .outer-link { 
                        float: left;
                        margin: 0 10px;
                        a { 
                            display: inline-block;
                            width: 61px;
                            height: 60px;
                            img {   
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                }

                .footer-search { 
                    float: left;
                    margin-left: 58px;
                    padding: 5px 0 0 0;
                    .footer-search-holder { 
                        width: 528px;
                        form { 

                            .form-field { 
                                margin: 0;
                                .input.addon { 

                                    .name { 

                                    }

                                    input { 
                                        border-color: $gray-light;
                                        border-radius: 100px;
                                        font-size: 16px;
                                        height: 45px;
                                        padding: 0 40px 0 20px;
                                        @include placeholder {
                                            font-size: 16px;
                                        }
                                        &:focus {
                                            border-color: $brand-primary;
                                        }
                                    }

                                    .addon { 

                                        .btn { 
                                            color: $brand-primary;
                                            background-color: $bg-secondary;
                                            border-color: $bg-secondary;
                                            width: 35px;
                                            height: 35px;
                                            line-height: 31px;
                                            border-radius: 50%;
                                            &:hover {
                                                color: white;
                                                background-color: $brand-primary;
                                                border-color: $brand-primary;
                                            }
                                            svg { 
                                                vertical-align: middle;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .right { 
                float: right;

                @import "phones_block";

                .phones-block { 
                    padding: 8px 0 0;
                    float: left;
                }

                .action { 
                    float: left;
                    padding: 8px 0 0;
                    .btn { 
                        width: 261px;
                        text-align: center;
                    }
                }

                .view { 
                    float: left;
                    padding: 8px 0 0;
                    margin-left: 15px;
                    .btn { 
                        width: 45px;
                        height: 45px;
                        line-height: 38px;
                        text-align: center;
                        border-radius: 50%;
                        color: $brand-primary;
                        background-color: $bg-secondary;
                        border-color: $bg-secondary;
                        padding: 0;
                        &:hover {
                            color: white;
                            background-color: $brand-primary;
                            border-color: $brand-primary;
                        }
                        svg { 
                            vertical-align: middle;
                        }
                    }
                }
            }
        }
	}

	.footer-middle { 
        display: inline-block;
        width: 100%;
		.footer-middle-holder { 
            display: inline-block;
            width: 100%;
            padding: 31px 0 29px;
            border-bottom: 1px solid $gray-light;
            .cols { 

                .col-50 { 

                   
                }
            }

            .link-list { 
                display: inline-block;
                width: 100%;
                &:not(:last-of-type) {
                    margin: 0 0 60px;
                }
                .heading { 
                    display: inline-block;
                    width: 100%;
                    font-size: 16px;
                    font-weight: bold;
                    margin: 0 0 23px;
                }

                ul { 
                    display: inline-block;
                    width: 100%;
                    padding: 0;
                    margin: 0;
                    list-style: none;
                    li { 
                        margin: 0;
                        &:not(:last-of-type) {
                            margin: 0 0 3.4px;
                        }
                        a { 
                            color: $gray;
                            font-size: 12px;
                            line-height: 130%;
                            &:hover {
                                color: $brand-primary;
                            }
                        }
                    }
                }
            }
        }
	}

	.footer-bottom { 
        display: inline-block;
        width: 100%;
		.footer-bottom-holder { 
            display: inline-block;
            width: 100%;
            padding: 35px 0 0;
            .left { 
                float: left;
                .copy { 
                    font-size: 14px;
                }
            }

            .right { 
                float: right;
                .socials { 
                    float: left;
                    ul { 
                        margin: -4px -10px 0;
                        list-style: none;
                        padding: 0;
                        li { 
                            float: left;
                            margin: 0 10px;
                            a {
                                display: inline-block; 
                                width: 24px;
                                height: 24px;
                                line-height: 21px;
                                border-radius: 50%;
                                background-color: $brand-primary;
                                color: white;
                                text-align: center;
                                &:hover {
                                    background-color: $brand-primary-accent;
                                }
                                svg { 
                                    vertical-align: middle;
                                }
                            }
                        }
                    }
                }

                .policy { 
                    float: left;
                    font-size: 14px;
                    margin-left: 95px;
                    a { 

                    }
                }

                .user-agreement { 
                    float: left;
                    font-size: 14px;
                    margin-left: 30px;
                    a { 

                    }
                }
            }
        }
	}
}

@import "../../media/tablet/includes/base/footer";
@import "../../media/mobile/includes/base/footer";

@media (max-width: $screen-sm) {
    // sm-view
    .footer-holder {
        .footer-top {
            .footer-top-holder { 
                display: inline-block;
                padding: 27px 0 20px;
                .left { 
                    width: 100%;
                    position: relative;
                    padding: 0 0 74px;
                    .logo {
                        float: left;
                        margin: 0;
                        a {
                            svg {
                                width: 107px;
                                height: auto;
                            }
                        }
                    }

                    .outer-links { 
                        float: right;
                        margin: 0 -7.5px;
                        .outer-link { 
                            margin: 0 7.5px;
                            padding: 5px 0 0;
                            a {
                                width: 30px;
                                height: 30px;
                            }
                        }
                    }

                    .footer-search {
                        bottom: 15px;
                        .footer-search-holder { 
                            form { 

                                .form-field { 
                                    .input.addon { 
                                        input { 
                                            padding: 0 40px 0 14px;
                                            font-size: 10px;
                                            @include placeholder {
                                                font-size: 10px;
                                            }
                                        }

                                        .addon { 

                                            .btn { 

                                                width: 30px;
                                                height: 30px;
                                                line-height: 27px;
                                                svg {
                                                    width: 10px;
                                                    height: auto;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .right { 
                    width: 100%;
                    display: flex;
                    .phones-block { 
                        margin-right: 10px;
                        .phones-dropdown {
                            right: auto;
                            left: 0;
                        }
                    }

                    .view { 
                        float: right;
                        margin-left: 10зч;
                        margin-right: -2px;
                    }

                    .action {
                        flex-grow: 1;
                        .btn {
                            width: 100%;
                        }
                    }
                }
            }
        }

        .footer-middle {
            .footer-middle-holder { 
                padding: 4px 0 5px;
                .cols {
                    margin-bottom: -16px;
                    .col {
                        margin: 0 0 16px;
                    }
                }

                .link-list { 
                    &:not(:last-of-type) {
                        margin: 0 0 17px;
                    }
                    .heading {
                        font-size: 12px;
                        margin: 0 0 10px;
                    }

                    ul {
                        li {
                            &:not(:last-of-type) {
                                margin: 0 0 5px;
                            }
                            a {
                                font-size: 12px;
                                line-height: 17px;
                            }
                        }
                    }
                }

              
            }
        }

        .footer-bottom { 
            .footer-bottom-holder { 
                padding: 33px 0 0;
                .left { 
                    .copy {
                        font-size:12px;
                        line-height: 17px;
                        margin: 10px 0 0;
                    }
                }

                .right { 
                    .socials { 
                        margin: 0 0 10px;
                        ul {
                            margin: -8px -2.5px 0;
                            li {
                                margin: 0 2.5px;
                            }
                        }
                    }

                    .policy { 
                        font-size: 12px;
                        line-height: 17px;
                        margin: 0 0 10px;
                        width: 100%;
                    }

                    .user-agreement { 
                        font-size: 12px;
                        line-height: 17px;
                        margin: 0;
                        width: 100%;
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}

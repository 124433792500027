@media (max-width: $screen-sm) {
    // sm-view
    &.xs-fixed {
        height: 82px;
        display: inline-block;
        .header-holder { 
            width: 100%;
            position: fixed;
            left: 0;
            top: 0;
        }
    }

    .header-holder { 
        padding: 15px 0;

        .additionals { 
            margin-right: 5px;
        }

        .header-top { 
            .header-top-holder { 
                padding: 5px 5px 5px;
                display: inline-block;
                .logo {
                    margin-left: 15px;
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}

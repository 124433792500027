@media (max-width: $screen-lg) {
    // lg-md view
    .footer-holder {
        .footer-top {
            .footer-top-holder { 
                .left { 
                    .logo { 
                        margin-right: 30px;
                    }

                    .outer-links { 

                    }

                    .footer-search { 
                        margin-left: 30px;
                        .footer-search-holder { 
                            width: 445px;
                        }
                    }
                }

                .right { 
                    .action { 
                        .btn { 
                            width: 161px;
                            padding: 11.5px 10px;
                        }
                    }
                }
            }
        }

        .footer-middle { 

        }

        .footer-bottom { 
            .footer-bottom-holder { 
                .left { 
                    .copy {
                        max-width: 600px;
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
    .footer-holder { 
        .footer-top { 
            .footer-top-holder { 
                display: flex;
                justify-content: space-between;
                position: relative;
                padding: 40px 0 80px;
                .left { 
                    .logo {
                        margin-right: 46px;
                        a {
                            
                        }
                    }

                    .outer-links { 
                        .outer-link { 
                            padding: 8px 0 0;
                            a {
                                width: 40px;
                                height: 40px;
                                img {

                                }
                            }
                        }
                    }

                    .footer-search { 
                        margin: 0;
                        padding: 0;
                        width: 100%;
                        position: absolute;
                        left: 0;
                        bottom: 22px;
                        z-index: 2;
                        .footer-search-holder { 
                            width: 100%;
                            form { 

                                .form-field { 
                                    .input.addon { 

                                        .name { 
    
                                        }
    
                                        input {
                                            font-size: 14px;
                                            height: 40px;
                                            @include placeholder {
                                                font-size: 14px;
                                            } 
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .right { 
                    display: flex;
                    justify-content: end;
                    flex-grow: 1;
                    .view { 
                        margin-left: 10px;
                        margin-right: -2px;
                    }

                    .action {
                        flex-grow: 0.75;
                        .btn {
                            width: 100%;
                            padding: 10.5px 10px;
                        }
                    }
                }
            }
        }

        .footer-middle { 
            .footer-middle-holder { 
                padding: 18px 0 29px;
                .link-list { 
                    &:not(:last-of-type) {
                        margin: 0 0 25px;
                    }
                    .heading { 
                        font-size: 14px;
                        margin: 0 0 21px;
                    }

                    ul { 
                        li {
                            &:not(:last-of-type) {
                                margin: 0 0 2.9px;
                            }
                            a {
                                font-size: 10px;
                            }
                        }
                    }
                }
            }
        }

        .footer-bottom { 
            .footer-bottom-holder { 
                display: flex;
                flex-direction: column-reverse;
                padding: 16px 0 0;
                .left {
                    width: 100%;
                    .copy {
                        width: 100%;
                        max-width: none;
                        font-size: 12px;
                        margin: 15px 0 0;
                    }
                }

                .right {
                    width: 100%;
                    .socials { 
                        ul {
                            margin: -4px -8px 0;
                            li { 
                                margin: 0 8px;
                            }
                        }
                    }

                    .policy { 
                        font-size: 12px;
                        margin-left: 29px;
                    }

                    .user-agreement { 
                        font-size: 12px;
                        margin-left: 19px;
                    }
                }
            }
        }
    }
}


&.float {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
}
&.white {
    .header-holder { 
        .header-top { 
            .header-top-holder { 
                background-color: white;
                &:before {
                    background-color: white;
                }
                .mobile-menu { 
                    >.btn {
                        background-color: $bg-secondary;
                    }

                    .local-mobile-menu-bkg {
                        background-color: $bg-secondary;
                    }

                    .local-mobile-menu { 
                        .local-mobile-menu-holder {
                            .menu { 
                                background-color: white;
                                .main-menu { 
                                    nav { 
                                        >ul { 
                                            >li { 
                                                a {
                                                    background-color: $bg-secondary;
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            .bottom-adds { 
                                .bottom-adds-holder { 
                                    .additionals { 
                                        .add { 
                                            .add-holder { 
                
                                                >.btn { 
                                                    background-color: white;
                                                    border-color: white;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .main-menu { 
                    nav { 

                        >ul { 
                            >li.has-sub-menu {
                                .sub-menu {
                                    .sub-menu-holder {
                                        background-color: white;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .additionals { 
        .add {
            .add-holder {
                >.btn { 
                    background-color: $bg-secondary;
                    border-color: $bg-secondary;
                    &:hover {
                        background-color: $brand-primary;
                        border-color: $brand-primary;
                    }
                }
            }
        }
    }
}

.additionals { 
    margin-right: 33px;
    .add { 
        float: left;
        margin: 0 10px;
        .add-holder.search-js {
            position: relative;
            &:hover {
                .search-form-dropdown { 
                    transform: translateX(-50%) translateY(0px);
                    opacity: 1;
                    pointer-events: all;
                    touch-action: auto;
                    transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
                }
            }
            .search-form-dropdown { 
                display: inline-block;
                width: 470px;
                position: absolute;
                left: 50%;
                top: 100%;
                padding: 30px 0 0;
                transform: translateX(-50%) translateY(-10px);
                opacity: 0;
                pointer-events: none;
                touch-action: none;
                transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
                .search-form-dropdown-holder { 
                    display: inline-block;
                    width: 100%;
                    .search-form { 
                        display: inline-block;
                        width: 100%;
                        form { 
                            display: inline-block;
                            width: 100%;
                            .form-field { 
                                display: inline-block;
                                width: 100%;
                                label.input { 
                                    display: inline-block;
                                    width: 100%;
                                    position: relative;
                                    &.open {
                                        .search-results-block {
                                            transform: translateY(0px);
                                            opacity: 1;
                                            pointer-events: all;
                                            touch-action: auto;
                                            transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
                                        }
                                    }
                                    .name { 
            
                                    }
            
                                    input { 
                                        display: inline-block;
                                        width: 100%;
                                        height: 55px;
                                        border-radius: 100px;
                                        padding: 0px 100px 0 22px;
                                    }
            
                                    .addon {    
                                        right: 2px;
                                        .btn { 
                                            background-color: $brand-primary;
                                            border-color: $brand-primary;
                                            color: white;
                                            padding: 11.5px 25px;
                                            &:hover {
                                                background-color: $brand-primary-accent;
                                                border-color: $brand-primary-accent;
                                            }
                                        }
                                    }
                                    
                                    .search-results-block { 
                                        display: inline-block;
                                        width: 100%;
                                        position: absolute;
                                        left: 0;
                                        top: 100%;
                                        padding: 5px 0 0;
                                        transform: translateY(-10px);
                                        opacity: 0;
                                        pointer-events: none;
                                        touch-action: none;
                                        transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
                                        .search-results-block-holder { 
                                            display: inline-block;
                                            width: 100%;
                                            overflow: hidden;
                                            background-color: white;
                                            box-sizing: border-box;
                                            padding: 20px;
                                            .results { 
                                                display: inline-block;
                                                width: 100%;
                                                max-height: 245px;
                                                box-sizing: border-box;
                                                padding-right: 16px;
                                                ul { 
                                                    display: inline-block;
                                                    width: 100%;
                                                    padding: 0;
                                                    margin: 0 0 -14px;
                                                    li { 
                                                        display: inline-block;
                                                        width: 100%;
                                                        margin: 0 0 14px;
                                                        .btn { 
                                                            padding: 7px 12px;
                                                            font-weight: 400;
                                                        }
                                                    }
                                                }
                                            }
            
                                            .action { 
                                                display: inline-block;
                                                width: 100%;
                                                margin: 10px 0 0;
                                                .btn { 
                                                    display: inline-block;
                                                    width: 100%;
                                                    text-align: center;
                                                    background-color: $bg-secondary;
                                                    border-color: $bg-secondary;
                                                    &:hover {
                                                        background-color: $brand-primary;
                                                        border-color: $brand-primary;
                                                        color: white;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .add-holder.language-select {
            position: relative;
            &:hover {
                .btn { 
                    background-color: $brand-primary;
                    border-color: $brand-primary;
                    color: white;
                }
                .language-select-block { 
                    transform: translateX(-50%) translateY(0px);
                    opacity: 1;
                    touch-action: auto;
                    pointer-events: all;
                    transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
                }
            }
            .language-select-block { 
                position: absolute;
                left: 50%;
                top: 100%;
                padding: 30px 0 0;
                transform: translateX(-50%) translateY(-10px);
                opacity: 0;
                touch-action: none;
                pointer-events: none;
                transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
                .language-select-block-holder { 
                    background-color: white;
                    border-radius: 100px;
                    padding: 10.5px 10px;
                    .lnguages { 
                        margin: 0 -5px;
                        display: flex;
                        .language { 
                            float: left;
                            margin: 0 5px;
                            a { 
                                display: inline-block;
                                width: 24px;
                                height: 25px;
                                svg { 
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
        .add-holder { 

            >.btn { 
                border-radius: 50%;
                padding: 0;
                width: 45px;
                height: 45px;
                line-height: 39px;
                text-align: center;
                color: $brand-primary;
                background-color: white;
                border-color: white;
                &:hover {
                    background-color: $brand-primary;
                    border-color: $brand-primary;
                    color: white;
                }
                svg { 
                    vertical-align: middle;
                    width: 27px;
                    height: 27px;
                }
            }
        }
    }
}


.header-holder { 
    display: inline-block;
    width: 100%;
    padding: 30px 0;
	.header-top { 
        display: inline-block;
        width: 100%;
		.header-top-holder { 
            display: inline-block;
            width: 100%;
            background-color: $bg-secondary;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 20px 10px;
            box-sizing: border-box;
            border-radius: 100px;
            position: relative;
            &:before {
                content: "";
                display: inline-block;
                width: 100%;
                height: 100%;
                border-radius: 100px;
                position: absolute;
                left: 0;
                top: 0;
                z-index: 2;
                background-color: $bg-secondary;
            }
            .mobile-menu { 
                float: left;
                &.open {
                    >.btn {
                        .close {
                            opacity: 1;
                            transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
                        }

                        .open {
                            opacity: 0;
                            transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
                        }
                    }
                    .local-mobile-menu { 
                        max-height: calc(100vh - 58px);
                        max-height: calc(var(--app-height) - 58px);
                        transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
                    }
                    .local-mobile-menu-bkg {
                        opacity: 1;
                        transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
                    }
                }
                >.btn {
                    display: inline-block;
                    position: relative; 
                    width: 40px;
                    height: 40px;
                    line-height: 40px;
                    background-color: white;
                    border-radius: 50%;
                    padding: 0;
                    position: relative;
                    z-index: 3;
                    .open, .close {
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        width: 20px;
                        height: 20px;
                        transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
                        svg {
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .close {
                        opacity: 0;
                    }
                }

                .local-mobile-menu-bkg {
                    display: inline-block;
                    width: 110.35%;
                    height: 100vh;
                    height: var(--app-height);
                    background-color: white;
                    position: absolute;
                    left: -5.175%;
                    top: -15px;
                    z-index: -1;
                    pointer-events: none;
                    touch-action: none;
                    opacity: 0;
                    transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
                }

                .local-mobile-menu { 
                    display: inline-block;
                    width: 100%;
                    max-height: 0;
                    position: absolute;
                    left: 0;
                    top: calc(100% - 22px);
                    overflow: hidden;
                    z-index: -1;
                    transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
                    .local-mobile-menu-holder { 
                        display: inline-block;
                        width: 100%;
                        height: calc(100vh - 58px);
                        height: calc(var(--app-height) - 58px);
                        .menu { 
                            display: inline-block;
                            width: 100%;
                            height: calc(100% - 58px);
                            overflow-y: auto;
                            background-color: $bg-secondary;
                            padding: 36px 15px 0;
                            border-radius: 0 0 20px 20px;
                            box-sizing: border-box;
                            .main-menu { 
                                display: inline-block;
                                width: 100%;
                                nav { 
                                    display: inline-block;
                                    width: 100%;
                                    >ul { 
                                        width: 100%;
                                        float: left;
                                        padding: 0;
                                        margin: 0;
                                        >li { 
                                            width: 100%;
                                            float: left;
                                            margin: 0 0 5px;
                                            padding: 0;
                                            >a { 
                                                display: inline-block;
                                                width: 100%;
                                                background-color: white;
                                                border-radius: 100px;
                                                width: 100%;
                                                font-size: 14px;
                                                line-height: 20px;
                                                padding: 5px 18px;
                                                color: $gray-base;
                                                box-sizing: border-box;
                                            }
                                        }
                
                                        >li.has-sub-menu { 
                                            &:hover {
                                                >a {
                                                    background-color: $brand-primary;
                                                    color: white;
                                                }

                                                .sub-menu { 
                                                    max-height: 500px;
                                                    transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
                                                }
                                            }

                                            >a { 
                
                                            }
                
                                            .sub-menu { 
                                                width: 100%;
                                                max-height: 0;
                                                float: left;
                                                overflow: hidden;
                                                transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
                                                position: relative;
                                                left: auto;
                                                top: auto;
                                                transform: none;
                                                padding: 0;
                                                .sub-menu-holder { 
                                                    display: inline-block;
                                                    width: 100%;
                                                    margin: 5px 0 0;
                                                    background-color: $brand-primary;
                                                    box-sizing: border-box;
                                                    padding: 16px 15px 16px;
                                                    ul { 
                                                        list-style: none;
                                                        width: 100%;
                                                        float: left;
                                                        padding: 0;
                                                        margin: 0;
                                                        li { 
                                                            padding: 0;
                                                            width: 100%;
                                                            float: left;
                                                            font-size: 14px;
                                                            line-height: 20px;
                                                            &:not(:last-of-type) {
                                                                margin: 0 0 5px;
                                                            }
                                                            a { 
                                                                color: white;
                                                                white-space: wrap;
                                                                background-color: transparent;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                
                        .bottom-adds { 
                            width: 100%;
                            height: 55px;
                            padding-top: 15px;
                            float: left;
                            box-sizing: border-box;
                            position: relative;
                            .bottom-adds-holder { 
                                display: flex;
                                align-items: center;
                                width: 100%;
                                .additionals { 
                                    float: left;
                                    margin: 0 -5px;
                                    .add { 
                                        float: left;
                                        margin: 0 5px;
                                        .add-holder.language-select {
                                            &:hover {
                                                .language-select-block {
                                                    transform: translateX(-50%) translateY(0px);
                                                }
                                            }
                                            .language-select-block {
                                                top: auto;
                                                bottom: 100%;
                                                padding: 0 0 10px;
                                                transform: translateX(-50%) translateY(10px);
                                            }
                                        }
                                        .add-holder { 
                
                                            >.btn { 
                                                width: 40px;
                                                height: 40px;
                                                line-height: 37px;
                                                background-color: $bg-secondary;
                                                border-color: $bg-secondary;
                                                >svg { 
                                                    width: 16px;
                                                    height: auto;
                                                }
                                            }

                                        }
                                    }
                                }
                
                                .action { 
                                    float: right;
                                    flex-grow: 1;
                                    margin-left: 10px;
                                    .btn { 
                                        width: 100%;
                                        text-align: center;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .logo { 
                float: left;
                position: relative;
                z-index: 3;
                a { 
                    display: inline-block;
                    width: 162px;
                    svg { 
                        width: 100%;
                        height: auto;
                    }
                }
            }

            .main-menu { 
                margin-left: 48px;
                position: relative;
                z-index: 3;
                nav { 

                    >ul { 
                        margin: 0 -5.5px;
                        >li { 
                            padding: 0 5.5px;
                            >a { 
                                display: inline-block;
                                padding: 11.5px 20px;
                                color: $gray-base;
                                &:hover {
                                    color: $brand-primary;
                                }
                            }
                        }
                        >li.has-sub-menu {
                            position: relative;
                            &:hover {
                                .sub-menu {
                                    opacity: 1;
                                    transform: translateY(0px);
                                    touch-action: auto;
                                    pointer-events: all;
                                    transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
                                }
                            }
                            .sub-menu {
                                position: absolute;
                                left: 0;
                                top: 100%;
                                padding: 30px 0 0;
                                opacity: 0;
                                transform: translateY(-10px);
                                touch-action: none;
                                pointer-events: none;
                                transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
                                .sub-menu-holder {
                                    background-color: $bg-secondary;
                                    overflow: hidden;
                                    padding: 40px 40px 40px;
                                    >ul {
                                        column-count: 2;
                                        column-gap: 100px;
                                        list-style: none;
                                        padding: 0;
                                        margin: 0;
                                        >li {
                                            &:not(:last-of-type) {
                                                margin: 0 0 21px;
                                            }
                                            a {
                                                color: $gray-base;
                                                white-space: nowrap;
                                                &:hover {
                                                    color: $brand-primary;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }


            .right { 
                display: flex;
                height: auto;
                align-items: center;
                justify-content: end;
                position: relative;
                z-index: 3;
                
                @import "phones_block";

                .action { 

                    .btn {  
                        width: 261px;
                        text-align: center;
                    }
                }
            }
        }
	}
}

@import "../../media/tablet/includes/base/header";
@import "../../media/mobile/includes/base/header";

@media (max-width: $screen-sm) {
    // sm-view
    .slick-slider {
        width: calc(100% + 15px);
        margin-left: -7.5px;
        .slick-list {
            .slick-track {
                .slick-slide {
                    padding: 0 7.5px;
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}

@media (max-width: $screen-sm) {
    // sm-view
    .general-popup.fancybox__content {
        padding: 30px 30px;
    }

    .popup {
        .custom-close {
            a {
                width: 20px;
                height: 20px;
                line-height: 20px;
                svg {
                    width: 6px;
                    height: 6px;
                }
            }
        }
    }
    
    .general-popup-container {
        max-width: 230px;
        .general-popup-container-holder {
            .success {
                .success-holder {
                    .icon {
                        margin: 0 0 10px;
                        .icon-holder {
                            width: 40px;
                            height: 40px;
                            line-height: 40px;
                            svg {
                                width: 14px;
                            }
                        }
                    }
                }
            }

            .form-heading {
                margin: 0 0 20px;
                .text {

                }

                .note {
                    width: 100%;
                    margin: 10px auto 0;
                    font-size: 10px;
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}

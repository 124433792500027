.back-to-top {
    position: fixed;
    right: 60px;
    bottom: 33%;
    z-index: 2;
    .back-to-top-holder {
        a {
            &.shown {
                opacity: 1;
                pointer-events: all;
                touch-action: auto;
                transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
            }
            width: 60px;
            height: 60px;
            line-height: 53px;
            padding: 0;
            border-radius: 50%;
            text-align: center;
            opacity: 0;
            pointer-events: none;
            touch-action: none;
            transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
            svg {
                vertical-align: middle;
            }
        }
    }
}

@import "../../media/tablet/includes/base/back_to_top";
@import "../../media/mobile/includes/base/back_to_top";
